<template lang="html" src="./pageUserLoginHistory.template.vue"></template>

<style lang="scss" src="./pageUserLoginHistory.scss"></style>

<script>
import { ApiErrorParser, DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageUserLoginHistory.i18n');

export default {
  name: 'PageUserLoginHistory',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      headers: [],
      loginHistory: [],
      search: '',
      loading: false,
      show: 0,
      loadingTable: false,
      portalFilter: [],
      browserFilter: [],
      osFilter: [],
      filterObject: {
        portal: [
          {
            name: 'admin',
            value: 'admin',
          },
          {
            name: 'industrial',
            value: 'industrial',
          },
          {
            name: 'supplier',
            value: 'supplier',
          },
          {
            name: 'portal-component',
            value: 'portal-component',
          },
        ],
      }
    };
  },
  created() {
    this.getLoginHistory();
  },
  mounted() {
    this.headers = [
      { 
        text: this.$t('Date'),
        value: 'date',
        sort: (a, b) => {
          return -1;
        }
      },
      { 
        text: this.$t('Portal'),
        value: 'portal',
        filter: (value) => {
          if (this.portalFilter.length === 0) return true;
          if (this.portalFilter.includes(value)) return true;
        },
      },
      { text: this.$t('Browser'),
        value: 'browserName',
      },
      { text: this.$t('Version'), value: 'browserVersion' },
      { 
        text: this.$t('OS'), 
        value: 'browserOS',
      },
      { text: this.$t('Zoom'), value: 'browserZoom' },
    ];
  },
  methods: {
    getLoginHistory() {
      this.loadingTable = true;
      this.loading = true;
      this.$apiInstance
        .getUserLoginHistory(this.$route.params.userUUID)
        .then(
          (data) => {
            this.loginHistory = data;
          },
          (error) => {
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loadingTable = false;
          this.loading = false;
        });
    },
    customFilter(value, search, item) {
      if (!search) return true;
      if (item.created) {
        if (this.$d(new Date(item.created), 'long').toString().includes(search)) return true;
      }
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    updateFilter(updatedFilter) {
      this.portalFilter = updatedFilter.portal;
    },
  },
};
</script>
