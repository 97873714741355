var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-userLoginHistory"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('LoginHistory')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "color": "primary",
      "title": _vm.$t('refreshPage')
    },
    on: {
      "click": _vm.getLoginHistory
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _vm.loginHistory ? _c('v-card', [_c('v-card-title', [_c('DataTableFilter', {
    ref: "filter",
    staticClass: "col-11 col-lg-7 pl-0",
    attrs: {
      "width-of-filters-titles": "2",
      "filters": _vm.filterObject
    },
    on: {
      "update": _vm.updateFilter
    }
  }), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.loginHistory,
      "search": _vm.search,
      "loading": _vm.loadingTable,
      "custom-filter": _vm.customFilter,
      "single-expand": true,
      "item-key": "created",
      "sort-by": "created",
      "sort-desc": true,
      "expanded": _vm.expanded,
      "show-expand": "",
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: `item.date`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long", _vm.$userLocale)) + " ")];
      }
    }, {
      key: `item.portal`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.portal) + " ")];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "colspan": _vm.headers.length
          }
        }, [_c('p', {
          staticClass: "subtitle-2 mt-4"
        }, [_vm._v(" Navigator ")]), _c('pre', [_vm._v(_vm._s(item.navigatorDump))]), _c('p', {
          staticClass: "subtitle-2 mt-4"
        }, [_vm._v(" Screen ")]), _c('pre', {
          staticClass: "mb-3"
        }, [_vm._v(_vm._s(item.screenDump))])])];
      }
    }], null, true)
  })], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }